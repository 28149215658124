.content-hw{
    overflow-x: hidden;
    height: auto;
}

.image-slider{
    position: absolute;
    top: 0;
    width: 100%;
    min-height: 92%;
    background-image: url("/src/images/banner.png") ;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}
.app-icon-container{
    width: 200px;
    height: 42px;
    object-fit: contain;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
}
/*.carousel-image{*/
/*    height: 70vh;*/
/*    width: inherit;*/
/*    opacity: 0.2;*/
/*}*/
.carousel-row > .rec-carousel-wrapper > .rec-carousel > button {
    color: #FFFFFF;
    box-shadow: none;
    background-color: transparent;
}
.carousel-body-contents{
    margin-top: 150px;
    margin-left: 50px;
    z-index: 0;
    display: flex;
    flex-direction: row;
    height: 43vh;
}
.content-image > img {
    width: 100%;
    height: 30vh;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.4);
    background-color: #FFFFFF;
}
.content-details{
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    font-family: SFPro;
    font-size: 1rem;
    color: #ffffff;
}
.content-details-header{
    font-family: SFPro;
    font-size: 3vh;
    font-weight: bold;
    line-height: 1.31;
    color: #FFFFFF;
}
.content-details-p{
    margin: 13.5px 12.4px 0.5px 1px;
    font-family: SFPro;
    font-size: 2vh;
    line-height: 1.31;
    color: #FFFFFF;
}

.flip-book{
    position: absolute;
    top: 64%;
    left: 35%;
    height: auto;
    width: 30%;
}

.body-contents{
    margin-top: 92vh;
}
.info-div{
    width: 100%;
    height: 100px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
}
.info-div-content{
    display: flex;
    margin-top: 0px;
    width: 335px;
    height: 65px;
}
.info-div-logos{
    width: 30px;
    height: 25.7px;
    margin: 10px 30px 21.3px 0;
}
.info-div-content-h {
    font-family: SFPro;
    font-size: 16px;
    line-height: 1.19;
    color: #000000;
}
.info-div-content-p{
    font-family: SFPro;
    font-size: 12px;
    line-height: 1.17;
    color: #545454;
}
.app-download-section{
    width: auto;
    height: 80vh;
    background-image: url("/src/images/app-download-sec-bg.webp") ;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 150px;
}
.logo-link-section{
    position: absolute;
    top: 215px;
    left: 240px;
}

.headline{
    font-family: SFPro;
    font-size: 1.6vw;
    font-weight: bold;
    line-height: 1.19;
    letter-spacing: normal;
    color: #000000;
    margin-bottom: 20px;
    margin-top: 10px;
}


.app-logos > a > img{
    width: 30%;
    height: 50%;
    margin-right: 30px;
}

.phone-image {
    position: relative;
    top: 15%;
    height: 100%;
    width: 42%;
}
@media  screen and (max-width: 1366px) {
    .phone-image {
        position: relative;
        top: 35%;
        height: 100%;
        width: 42%;
    }
}
@media  screen and (max-width: 12px) {
    .phone-image {
        position: relative;
        top: 35%;
        height: 100%;
        width: 50%;
    }
}
.book-carousel{
    margin: 12px  5%;
}
.categorydiv{
    width: 100%;
    height: auto;
    margin: 0px auto;
    overflow: hidden;
}
.client-section{
    margin-top: -15px;
    width: auto;
    height: 80vh;
    background-image: url("/src/images/client-sec-bg.png") ;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.client-section-body{
    position: relative;
    top: 17%;
    left: 12%;
    width: 76%;
    height: 65%;
    opacity: 0.95;
    border-radius: 20px;
    background-color: #ffffff;
}
.client-section-header{
    position: absolute;
    left: 37%;
    top: 8%;
    font-family: SFPro;
    font-size: 1.5vw;
    line-height: 1.19;
    color: #545454;
}
.client-body-image{
    position: absolute;
    top: 26%;
    left: 25%;
    width: 50%;
    height: auto;
}
.client-section-footer{
    position: absolute;
    left: 0%;
    top: 76%;
    font-family: SFPro;
    font-size: .8vw;
    color: #545454;
    margin: 0 100px;
}
.send-us-msg-section{
    width: 100%;
    margin-bottom: 20px;
}

.send-us-msg-section-header{
    margin-top: 50px;
    font-family: SFPro;
    font-size: 1.5vw;
    font-weight: bold;
    line-height: 1.19;
    color: #000000;
}
.send-msg-image{
    position: relative;
    width: 44%;
    height: auto;
    margin: 12% 22%;
    object-fit: contain;
}

.input-message-section{
    margin-top: 50px;
    margin-bottom: 30px;
}
.send-message-inputs{
    width: 89%;
    margin: 0 0 15px;
    padding: 20px 288px 19px 23px;
    border-radius: 10px;
    border: solid 2px #ededed;
    background-color: #ffffff;
}
/*.hidden{*/
/*    visibility: hidden;*/
/*}*/
/*.visible{*/
/*    visibility: visible!important;*/
/*}*/