.users-list-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.order-modal-cross-button{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 70px;
    justify-content: flex-end;
    border-bottom: 1px solid #dbdbdb;
}
.order-modal-cross-button> i {
    margin-right: 50px;
    font-size: 20px;
    cursor: pointer;
}