.related-books-main-div{
    margin: 0;
}
.related-book-name{
    margin-left: 90px;
    font-family: SFPro;
    font-size: 28px;
    text-align: left;
    color: #000000;
}
.related-books {
    display: flex;
    padding: 0px;
    font-family: SFPro;
    margin-bottom: 20px;
}
.related-books-carousel > .rec-carousel > button{
    margin-bottom: 100px;
    color: #333333;
    box-shadow: none;
    background-color: transparent;
}
.related-books-carousel > .rec-carousel > button:hover{
    color: #333333;
    box-shadow: none;
    background-color: #FFFFFF;
}
.related-books-carousel > .rec-carousel > button:focus{
    color: #333333;
    box-shadow: none;
    background-color: #FFFFFF;
}