.main-footer-div{
    width: 100vw;
    height: 100%;
    background-color: #F9F9F9;
}
.address-logo{
    margin-top: 60px;
    margin-left: 30%;
}
.bookflix-logo{
    margin-bottom: 20px;
}
.contents{
    margin: 10px 0;
}

.contents-div{
    margin-top: 60px;
}
.social-media-logo{
    margin: 20px 0;

}
.media-logos{
    color: #666666;
    border-radius: 20px;
    margin: 0 10px;
}

.copywrite {
    border-top: 1px solid #dbdbdb;
    height: auto;
}
.copywrite> span{
    margin: 10px 0;
}
.footer-arrow-logo{
    position: relative;
    top: 132px;
    width: 48px;
    height: 48px;
    cursor: pointer;
}
.footer-book-image{
    position: absolute;
    top: 28px;
    left: 83%;
    width: 95px;
    height: 56.6px;
}