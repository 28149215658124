.user-profile-edit-page-container{
    min-height: 300px;
    max-width: 800px;
    margin: 4% auto;
    padding: 30px;
    border: 1px solid #ced4da;
    border-radius: 10px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);
}
.user-information-edit{
    display: flex;
    justify-content: space-between;
}