
.r-publisher-image {
    margin-right: 50px;
    margin-bottom: 30px;
}
.r-publisher-image > div  .publisher-image {
    width: 160px;
    height: 200px;
    margin: 0 0 15px;
    border-radius: 10px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.24);
}
.r-publisher-image .publisher-title{
    width: 160px;
    margin: 0 auto;
    font-family: SFPro;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: #000000;
}
.r-publisher-image .no-of-books{
    margin: 0 auto;
    font-family: SFPro;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: rgb(245, 166, 35);
}
.r-publisher-image a:hover {
    text-decoration: none;
}
.icon-like-publisher  {
    width: 32px;
    height: 32px;
    margin: -60px 10px 28px 118px;
    padding: 4px;
    opacity: 0.7;
    border-radius: 20px;
    background-color: #000000;
}
