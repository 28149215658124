.about-us-banner{
    background-image: url("/src/images/about-us-banner.png") ;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: auto;
    min-height: 30vh;
    margin-bottom: 100px;
}
.about-us-banner-title{
    position: absolute;
    top: 20vh;
    left: 45%;
    font-family: SFPro;
    font-size: 35px;
    font-weight: bold;
    line-height: 1.19;
    color: #ffffff;
}
.about-us-details{
    margin: 0 auto;
    width: 540px;
    text-align: justify;
}