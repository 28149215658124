.category-main-div{
  margin: 0px 47px  ;
  border-bottom:  solid 1px #dbdbdb;
}
.category-name{
  margin-left: 90px;
  font-family: SFPro;
  font-size: 28px;
  text-align: left;
  color: #000000;
}
.category-books {
  display: flex;
  padding: 0px;
  font-family: SFPro;
  margin-bottom: 20px;
}
.category-carousel > .rec-carousel > button{
  margin-bottom: 100px;
  color: #333333;
  box-shadow: none;
  background-color: transparent;
}
.category-carousel > .rec-carousel > button:hover{
  color: #333333;
  box-shadow: none;
  background-color: #FFFFFF;
}
.category-carousel > .rec-carousel > button:focus{
  color: #333333;
  box-shadow: none;
  background-color: #FFFFFF;
}