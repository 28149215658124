.navigation-bar {
    position: sticky;
    top: 0;
    background: transparent;
    z-index: 100;
    width: 100%;
    height: 90px;

}
.navigation-bar.active {
    position: sticky;
    background: #FFFFFF;
    border-bottom: 1px solid #dbdbdb;
}
.nav-logo {
    z-index: 100;
    margin-left: 10%;
}
.nav-menu{
    margin-left: 10%;
}

.nav-items {
    margin-left: 90px;
}

.nav-items .nav-links {
    width: 70px;
    height: auto;
    margin: 0 0 0px 20px;
    font-size: 1rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: #545454;
    z-index: 100;
    cursor: pointer;
}

.nav-links:hover {
    text-decoration: none;
    color: #046AB3 ;
}

.right-nav-items {
    position: relative;
    right: 25.5%;
    transform: translate(-50%, -50%);
    z-index: 100;
    top: 12px;
}

.search-icon {
    position: relative;
    top: 15px;
    right: -87px;
    transform: translate(-50%, -50%);
    color: #545454;
    cursor: pointer;
    width: auto;
    height: 24px;
}
.search-input{
    display: block;
    position: absolute;
    top: -3px;
    right: -88px;
    height: auto;
    width: 20vw;
    padding: .500rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 10px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.cart-icon {
    position: absolute;
    right: -126px;
    top: -2px;
    font-size: 22px;
    cursor: pointer;
    color: #545454;
}

.quantity-in-cart {
    position: absolute;
    top: 2px;
    right: -146px;
    transform: translate(-50%, -50%);
    font-size: 14px;
    color: #f1f5f6;
    background-color: #1C85DF;
    height: 18px;
    width: 18px;
    text-align: center;
    border-radius: 20px;
}

.account-icon {
    position: absolute;
    top: -8px;
    right: -225px;
    transform: translate(-50%, -50%);
    color: #545454;
    cursor: pointer;
}
.account-icon-white {
    position: absolute;
    top: -8px;
    right: -225px;
    transform: translate(-50%, -50%);
    color: #ffffff;
    cursor: pointer;
}
.user-name{
    position: absolute;
    top: 14px;
    right: -247px;
    transform: translate(-50%, -50%);
    color: #545454;
}

.search-card{
    position: absolute;
    right: -101px;
    top: 10px;

}


.search-div {
    position: absolute;
    top: 38px;
    right: 13px;
    max-height: 200px;
    width: 20vw;
    background-color: #ffffff;
    border: 1px solid #ced4da;
    overflow: hidden;
    background-clip: padding-box;
    border-radius: 10px;
    z-index: 50;
}

.login-card {
    position: absolute;
    right: -101px;
    top: 10px;
}
.login-div {
    position: absolute;
    top: 273px;
    left: -86px;
    transform: translate(-50%, -50%);
    width: 390px;
    height: 460px;
    border-radius: 10px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);
    background-color: #FBFBFB;
    z-index: 50;
}

.triangle {
    position: absolute;
    top: 37px;
    left: 61px;
    transform: translate(-40%, -50%);
    width: 0;
    height: 0;
    border-width: 0 17px 15px 17px;
    border-color: transparent transparent #FFFFFF transparent;
    border-style: solid;
    filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.2));

}
.home-account-card{
    position: absolute;
    right: -101px;
    top: 10px;
}
.home-account-div{
    position: absolute;
    left: 15px;
    transform: translate(-50%, -50%);
    width: 192px;
    min-height: 215px;
    border-radius: 10px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);
    background-color: #FBFBFB;
    z-index: 50;
}
.home-account-div > div {
    font-family: SFPro;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.27;
    letter-spacing: normal;
    text-align: left;
    color: #545454;
    cursor: pointer;
}
.home-account-div > div:hover {
    color: #fb8838;
}
