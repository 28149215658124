.app-icon-container{
    width: 200px;
    height: 42px;
    object-fit: contain;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
}

.app-logos-sec{
    display: flex;
    position: absolute;
    cursor: pointer;
    top: 4px;
    left: 5px;
    width: 34px;
    height: 34px;
    padding: 1px 6px 5px 6px;
    border-radius: 20px;
    background-image: linear-gradient(to right, #0067b1, #1c85df 92%);
}
.app-store-name{
    margin-top: 5px;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    color: #545454;
}