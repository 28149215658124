.pending-product-review-info{
    max-width: 800px;
    margin: 5% auto;
    padding: 30px;
    border: 1px solid #ced4da;
    border-radius: 10px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);
}
.pending-review-posts{
    padding: 10px;
    margin-bottom: 14px;
    height: 200px;
    border-radius: 10px;
    border: solid 1px #dbdbdb;
    background-color: #f6f6f6;
}
.pending-reviewer-name-image > img {
    width: 45px;
    height: 45px;
    border-radius: 30px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    margin-right: 10px;
}