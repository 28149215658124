.multiselect-search-results {
    display: flex;
    flex-direction: column;
}
.multiselect-search-results-disabled {
    background-color: lightpink;
}

.multiselect-search-results p {
    margin: 0;
    padding-top: 10px;
    padding-left: 10px;
    height: 50px;
    width: 100%;
    border-bottom: #333333;
    font-size: 18px;
    font-family: SFPro;
    font-weight: 500;
    color: #333333;
}

.multiselect-search-results-enabled p:hover {
    background-color: #EEEEEE;

}