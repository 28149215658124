.author-info-form{
    max-width: 800px;
    margin: 5% auto;
    padding: 30px;
    border: 1px solid #ced4da;
    border-radius: 10px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);
}
.author-form-main-div label{
    font-size: 16px;
    font-weight: bold;
}