.user-profile-page-container{
    min-height: 300px;
    max-width: 800px;
    margin: 4% auto;
    padding: 30px;
    border: 1px solid #ced4da;
    border-radius: 10px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);
}
.user-information{
    display: flex;
    justify-content: space-between;
}
.user-image > span > img {
    width: 65px;
    height: 65px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}