.main-book-details-template {
  margin: 20px 12%;
}
.related-books-details-main-div{
  margin: 0 7%;
}
.book-image > img{
  width: 350px;
  height: 508px;
  /*margin: 34px 12%;*/
  border-radius: 10px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.24);
}
.details {
  margin: 40px 0 0 2vw;
}

.details .title {
  width: 296px;
  min-height: 29px;
  margin: 0 36px 10px 0;
  font-family: "SutonnyMJ";
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.83;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}
.rating-price{
  line-height: 1.5;
}
.rating-star{
  margin-right: 10px;
}
i{
  margin: 0 4px;
}
.product-price{
  margin: 0 0 0 30px;
  font-family: "SFPro";
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  color: #545454;
  line-height: 2;
}
.book-details-author-name  {
  margin: 10px 126px 0 2px;
  font-family: "SFPro";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}
.book-details-author-name:hover  {
  color: #0067B1;
}
.details .details-contents{
  margin: 30px 99px 10px 0;
  font-family: "SFPro";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.31;
  letter-spacing: normal;
  text-align: left;
  color: #545454;
}
 .book-details-category-name  span{
  margin: 0 5px;
   color: #545454;
 }
.book-details-category-name span:hover{
  color: #0067B1;
}

.app-download-info{
  width: 332px;
  height: 59px;
  margin: 30px 0 0;
  opacity: 0.6;
  font-family: "SFPro";
  font-size: 14px;
  line-height: 1.43;
  color: #545454;
}

.qr-section {
  margin: 82px auto;
}

.QR-code > svg{
    margin: 25px  35px;
}
.scan-text {
  width: 162px;
  height: 19px;
  margin: 0px  38px;
  font-family: "SFPro";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.price {
  font-size: 37px;
  font-weight: 500;
  color: rgb(245, 166, 35);
  margin-top: 26px;
}
.add-to-cart {
  margin-left: 24px;
}
.add-to-cart > button{
  font-size: 13px;
  color: #FFFFFF;
  margin: 40.6px 0 30px;
  padding: 12.5px 40px 11.5px;
  border-radius: 30px;
  background-image: linear-gradient(to right, #0067b1, #1c85df 96%);
}
.write-review-button > Button{
  color: #FFFFFF;
  padding: 13px 40px;
  border-radius: 30px;
  background-image: linear-gradient(to right, #0067b1, #1c85df);
}
.review-post-button > Button{
  color: #FFFFFF;
  margin-top: 5px;
  padding: 13px 40px;
  border-radius: 30px;
  background-image: linear-gradient(to right, #0067b1, #1c85df);
}
.book-info {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: justify;
}

.book-info h3 {
  padding: 0px;
}
.book-info p {
  font-family: "SFPro";
  opacity: 0.8;
}

hr {
  opacity: 0.2;
  margin: 50px 0px;
}
.reviews-section {
  grid-area: review;
}

.related-books {
  grid-area: r-books;
  display: flex;
  padding: 0px;
  font-family: "SFPro";
}
.book-author-details{
  min-height: 100px;
}
.book-author-details > nav > a.active {
  color: #1c85df !important;
}
.book-author-details > nav > a:hover {
  color: #545454 !important;
}
.nav-tabs > a {
  color: #545454;
}
@media  screen and (max-width: 767px) {
  .book-image > img{
    width: 100%;
    height: auto;
    margin: 40px auto;
  }
  .mobile-contents{
    display: flex;
    justify-content: center;
    margin: 0;
  }

}
.review-div-sec{
  margin-bottom: 30px;
  height: 150px;
  padding: 20px 0;
  border-radius: 10px;
  border: solid 1px #dbdbdb;
}

.review-post-sec{
  padding: 10px;
  height: 250px;
  border-radius: 10px;
  border: solid 1px #dbdbdb;
  background-color: #f6f6f6;
}
.comment-section{
  margin-top: 30px;
  min-height: 150px;
  border-bottom: 1px solid #dbdbdb;
}
.commenter-image > img{
  width: 65px;
  height: 65px;
  border-radius: 30px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.commenter-name-and-rating{
  margin-left: 30px;
}
.commenter-name{
  font-size: 16px;
  font-weight: bold;
  color: #545454;
}
.comments{
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 16px;
  font-style: normal;
  line-height: 1.31;
  color: #545454;
}
@media  screen and (max-width: 1366px) {
  .book-image > img{
    margin-top: 20px;
    width: 270px;
    height: 400px;
  }
  .mobile-contents{
    display: flex;
    margin: 0;
  }

}
