.main-body-div{
    background-color: #ffffff;
    overflow: hidden;
    height: 100vh;
}
.site-logo{
    width: 40vw;
    height: auto;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%)
}
.app-link-logo-div{
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%)
}
.playstore-image{
    width:43vw;
    height: 100%;
    padding-right: 20px;
}
.appstore-image{
    width:40vw;
    height: 100%;
}