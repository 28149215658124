.privacy-policy-banner{
    background-image: url("/src/images/terms-and-conditions.png") ;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: auto;
    min-height: 30vh;
    margin-bottom: 100px;
}
.privacy-policy-banner-title {
    position: absolute;
    top: 20vh;
    left: 40%;
    font-family: SFPro;
    font-size: 35px;
    font-weight: bold;
    line-height: 1.19;
    color: #ffffff;
}
.privacy-policy-details{
    margin: 0 5% 0 0;
    text-align: justify;
}
.privacy-policy-table {
    border-collapse: collapse;
    width: 100%;
}
  
.privacy-policy-table > tr > td, .privacy-policy-table > tr > th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}