
.r-book-image {
    margin-bottom: 40px;
}

.r-book-image > div {
    width: 162px;
}

.r-book-image > div .book-image {
    width: 160px;
    height: 232px;
    margin: 0 0 15px;
    border-radius: 10px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.24);
}

.r-book-image .title {
    margin: 0 auto;
    font-family: SFPro;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.r-book-image .author-name {
    margin: 10px auto;
    font-family: SFPro;
    font-size: 14px;
    line-height: 1.14;
    text-align: center;
    color: #545454;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.r-book-image .price {
    margin: 0 auto;
    font-family: SFPro;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
}

.r-book-image a:hover {
    text-decoration: none;
}

.r-book-image p {
    font-family: SFPro;
    opacity: 0.5;
    color: black;
}

.icon-cart {
    width: 32px;
    height: 32px;
    margin: -62px 10px 28px 79px;
    padding: 4px;
    opacity: 0.7;
    border-radius: 20px;
    background-color: #000000;
    cursor: pointer;
}

.icon-like-book {
    width: 32px;
    height: 32px;
    margin: -60px 10px 28px 118px;
    padding: 4px;
    opacity: 0.7;
    border-radius: 20px;
    background-color: #000000;
    cursor: pointer;
}
