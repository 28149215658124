.order-details{
    margin: 0 30px;
}
.order-title {
    color: #686868;
    border-bottom: 1px solid #dbdbdb;
}
.order-details-body{
    border-bottom: 1px solid #dbdbdb;
    padding: 20px 0;
}
.order-details-body > h6{
    color: #686868;
}
.charges-text-label > div {
    font-weight: bold;
    padding: 12px 0;
}
.order-products-list{
    width: 100%;
    height: 232px;
    overflow-x: hidden;
    overflow-y: auto;
}