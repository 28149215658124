.all-category-books{
    display: flex;
    flex-wrap: wrap;
    padding: 0px;
    font-family: SFPro;
    margin-left: 13%;
    margin-bottom: 50px;
}
.category-books-details-banner{
    background-image: url("/src/images/category-all-books-banner.png") ;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: auto;
    min-height: 30vh;
    margin-bottom: 50px;
}
.category-banner-title{
    position: absolute;
    top: 20vh;
    left: 45%;
    font-family: SFPro;
    font-size: 35px;
    font-weight: bold;
    line-height: 1.19;
    color: #ffffff;
}
.category-book-detail-header-and-search-sec{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    margin-top: 50px;
    margin-bottom: 50px;
    background-color: #FFFFFF;
}
.category-book-detail-title-header{
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    color: #545454;
    margin-left: 13%;
}
.category-book-detail-search-input{
    margin-right: 12%;
}
.category-book-detail-search-input-field >div{
    width: 20vw;
    height: 50px;
    visibility: hidden;
}