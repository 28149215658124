.category-main-body{
    width: 100%;
    height: 131px;
    padding: 0 89px;
    background-color: #f9f9f9;

}
.category-rectangle{
    width: 100%;
    height: 131px;
    padding: 23px 0;
    object-fit: contain;
    text-align: center;
    cursor: pointer;
}
.category-rectangle:focus{
    background-color: #FFFFFF;
}

.category-main-body >div >div{
    width: 92%;
}

.category-carousel-image{
    height: 60px;
    width: 60px;
    border-radius: 30px;
    padding: 12px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
    background-color: #fff;
    margin: 0 auto;

}
.category-carousel-image >img{
    height: auto;
    width: 80%;
}


.category-carousel-name{
    padding: 10px 0;
    font-size: 14px;
}
.category-main-body > .rec-carousel-wrapper > .rec-carousel> button{
    color: #333333;
    box-shadow: none;
    background-color: transparent;
}
.category-main-body > .rec-carousel-wrapper > .rec-carousel> button:hover{
    color: #333333;
    box-shadow: none;
}
.category-main-body > .rec-carousel-wrapper > .rec-carousel> button:focus{
    color: #333333;
    box-shadow: none;
}