/*.search-dropdown {*/
/*    position: absolute;*/
/*    top: 200px;*/
/*    left: 70.8%;*/
/*    transform: translate(-50%, -50%);*/
/*    height: 300px;*/
/*    width: 20vw;*/
/*    background-color: #ffffff;*/
/*    border: 1px solid #ced4da;*/
/*    z-index: 30;*/
/*    overflow-y: auto;*/
/*    background-clip: padding-box;*/
/*    border-radius: 10px;*/
/*}*/

.search-results {
    display: flex;
    flex-direction: column;
}

.search-results p {
    margin: 0;
    padding-top: 10px;
    padding-left: 10px;
    height: 50px;
    border-bottom: #333333;
    font-size: 12px;
    font-family: SFPro;
    font-weight: 500;
    color: #333333;
}

.search-results p:hover {
    background-color: #EEEEEE;

}
@media screen and (max-width: 1366px) {
    .search-dropdown{
        left: 70.5%;
    }
}