body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  background-color: #ffffff;
  overflow-x: hidden;
}
@font-face {
  font-family: "SFPro";
  src: local("SFPro"), url("./fonts/SFPro.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "SutonnyMJ";
  src: local("SutonnyMJ"), url("./fonts/SutonnyMJ.ttf") format("truetype");
  font-weight: normal;
}

