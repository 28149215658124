
.r-author-image {
    margin-right: 50px;
    margin-bottom: 50px;
}
.r-author-image > div  .authors-image {
    width: 160px;
    height: 200px;
    margin: 0 0 15px;
    border-radius: 10px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.24);
}
.r-author-image .authors-title {
    width: 160px;
    margin: 0 auto;
    font-family: SFPro;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: #000000;
}
.r-author-image .no-of-books{
    margin: 0 auto;
    font-family: SFPro;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: rgb(245, 166, 35);
}
.r-author-image a:hover {
    text-decoration: none;
}
.authors-image-list{
    width: 160px;
    height: 200px;
    margin: 0 0 15px;
    border-radius: 10px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.24);
}

.author-rows{
    height: auto;
    margin: 30px 0;
    width: 90%;
    border-bottom: 1px solid #dbdbdb;
}

.title-authors{
    font-size: 16px;
    font-weight: bold;
    line-height: 1.14;
    color: rgba(0, 0, 0, 0.87);
}
.details-authors > p{
    width: 1150px;
    color: #545454;
}
.details-button-author{
    margin-bottom: 50px;
}
.icon-like-author  {
    width: 32px;
    height: 32px;
    margin: -60px 10px 28px 118px;
    padding: 4px;
    opacity: 0.7;
    border-radius: 20px;
    background-color: #000000;
}
