.r-category-image {
    margin-right: 30px;
    margin-bottom: 30px;
    width: 180px;
    height: 250px;
    border-radius: 10px;
    box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.24);
}

.r-category-image > .category-name-logo {
    text-align: center;
}

.category-image {
    margin-top: 40%;
}
.r-category-image > .category-name-logo > div {
    color: #545454;
    margin: 20px 0;
}