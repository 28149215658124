.all-publishers{
    display: flex;
    flex-wrap: wrap;
    padding: 0px;
    font-family: SFPro;
    margin-left: 13%;
    margin-top: 50px;
}
.publisher-header-and-search-sec{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    margin-top: 50px;
    background-color: #FFFFFF;
}
.publisher-title-header{
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    color: #545454;
    margin-left: 13%;
}
.publishers-search-input{
    margin-right: 12%;
}
.publishers-search-input-field >div{
    width: 20vw;
    height: 50px;
}
.publisher-pagination-row{
    width: 90%;
    justify-content: flex-end;
}
.publisher-paginator{
    padding: 0px;
    margin: 40px 0;
}
