.drawer-list-links{
    text-decoration: none;
    color: #FFFFFF;
}
.collapsable-drawer{
    color: #FFFFFF;
    margin-left: 20px;
}
.drawer-list-links:hover{
    text-decoration: none;
    color: #FFFFFF;
}
.admin-home-account-card{
    position: absolute;
    right: 255px;
    top: 35px;
}
.admin-home-triangle {
    position: absolute;
    top: 37px;
    left: 61px;
    transform: translate(-40%, -50%);
    width: 0;
    height: 0;
    border-width: 0 17px 15px 17px;
    border-color: transparent transparent #FFFFFF transparent;
    border-style: solid;
    filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.2));

}
.admin-home-account-div{
    position: absolute;
    top: 132px;
    left: 15px;
    transform: translate(-50%, -50%);
    width: 176px;
    min-height: 175px;
    border-radius: 10px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);
    background-color: #FBFBFB;
    z-index: 50;
}
.admin-home-account-div > div {
    font-family: SFPro;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.27;
    letter-spacing: normal;
    text-align: left;
    color: #545454;
    cursor: pointer;
}
.admin-home-account-div > div:hover {
    color: #fb8838;
}

.account-content-name:hover{
    color: red;
}
.makeStyles-drawerContainer-5 > .MuiList-root{
    padding: 0;
}