.main-multiselect-body{
    display: block;;
    flex-wrap: wrap;
    min-width: 600px;
}
.multiselect-data-list{
    display: flex;
    flex-wrap: wrap;
    grid-gap: 12px;
    gap: 12px;
}
.multiselect-search-input{
    height: auto;
    width: 100%;
    margin: 20px 0 10px 0;
    padding: .500rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 10px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.multiselect-search-icon{
    position: relative;
    top: -46px;
    right: -257px;
    color: #545454;
    cursor: pointer;
    width: auto;
    height: 24px;
}
.multiselect-search-div{
    position: absolute;
    height: 300px;
    min-width: 750px;
    background-color: #ffffff;
    border: 1px solid #ced4da;
    overflow: hidden;
    background-clip: padding-box;
    border-radius: 10px;
    z-index: 50;
}
.save-reset-buttons{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 20px;
}