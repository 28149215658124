.main-div{
  width: 100%;
  min-height: 100vh;
}
.main-cart-div{
    margin: 0 10%;
}
.banner{
  width: 100%;
  min-height: 30vh;
  background-image: url("/src/images/shopping-cart-banner.png") ;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
 .banner-title{
   position: absolute;
   top: 20vh;
   left: 45%;
   font-family: SFPro;
   font-size: 40px;
   font-weight: bold;
   line-height: 1.19;
   color: #ffffff;
}

 .cart-items-section{
   border-bottom: solid 2px #dbdbdb;
   text-align: center;
 }
.cart-item-sec-title{
  font-family: SFPro;
  font-size: 16px;
  font-weight: bold;
  line-height: 3.31;
  color: #545454;
}
.cart-item-details{
  margin: 20px 0;
  text-align: center;

}
.cart-item-details .cart-book-image{
  width: 64px;
  height: 96px;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.4);
}
.book-details{
  margin: 22px 0 10px 32px;
  font-family: SFPro;
  font-size: 16px;
  line-height: 1.19;
  color: #545454;
}
.cart-item-price{
  margin: 55px 0 0;
  font-family: SFPro;
  font-size: 16px;
  line-height: 1.19;
  color: #545454;
}
.cart-delete-button img{
    margin: 20px 0 0;
    cursor: pointer;
}
.payment-and-checkout{

}
.payment-methods{
    width: 90%;
    height: 352px;
    margin: 33px 20%;
    object-fit: contain;
}

.payment {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 40%;
    margin: 5% auto;
    padding: 0 15px 20px;
    border-radius: 4px;
    background-color: #fff;
}
.payment .payment-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: SF Pro;
    margin-top: 5px;
    flex-wrap: wrap;
}
.payment-header span {
    font-family: SFPro;
    font-size: 16px;
    font-weight: bold;
    color: #166fb7;

}
.payment-header .price {
    color: #25b7d3;
    font-size: 32px;
    font-weight: 800;
    line-height: 51px;
}
.payment .input-phone {
    margin-top: 10px;
}


.input-phone input {
    width: 100%;
    height: 48px;
    border-radius: 10px;
    border: solid 1px #dbdbdb;
    background-color: #ffffff;
}
.promo-apply {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: SF Pro;
    margin-top: 15px;
    flex-wrap: wrap;
}
.promo-apply .input-promo {
    height: 30px;
    font-size: 15px;
    padding: 2px 5px;
    border-radius: 10px;
    border: solid 1px #dbdbdb;
    background-color: #ffffff;
}
.promo-apply .btn-coupon {
    height: 27px;
    width: 25%;
    border: 1px solid #8a8fa2;
    color: #353b50;
    font-size: 15px;
    text-align: center;
    line-height: 5px;
    border-radius: 7px;
}
.btn-payment {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    font-size: 16px;
    text-align: center;
    margin-top: 25px;
    border-radius: 30px;
    background-image: linear-gradient(to right, #0067b1, #1c85df 96%);
}
.btn-payment span {
    color: #fff;
    font-family: SFPro;
    background: none !important;
}