.author-image{
    width: 160px;
    height: 188px;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.24);
}
.author-name {
    height: 19px;
    margin: 0 77px 6px 0;
    font-family: SFPro;
    font-weight: bold;
    font-size: 14px;
    line-height: 1.14;
    color: rgba(0, 0, 0, 0.87);
}
.no-of-books-like-button{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.like-logo{
    height: 32px;
    width: 32px;
    border-radius: 20px;
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.24);
}
.author-description{
    font-family: SFPro;
    font-size: 14px;
    line-height: 1.5;
    text-align: justify;
    color: #545454;
}
.author-all-books-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: SFPro;
    margin-left: 10%;
    margin-right: 15%;
    margin-bottom: 30px;
}
.all-author-books{
    display: flex;
    flex-wrap: wrap;
    padding: 0px;
    font-family: SFPro;
    margin-left: 10%;
    margin-right: 10%;
}
