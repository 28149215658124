.all-favourite-books{
    display: flex;
    flex-wrap: wrap;
    padding: 0px;
    font-family: SFPro;
    margin-left: 13%;
    margin-bottom: 50px;
}
.favourite-books-details-banner{
    background-image: url("/src/images/category-all-books-banner.png") ;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: auto;
    min-height: 30vh;
    margin-bottom: 100px;
}
.favourite-banner-title{
    position: absolute;
    top: 20vh;
    left: 45%;
    font-family: SFPro;
    font-size: 35px;
    font-weight: bold;
    line-height: 1.19;
    color: #ffffff;
}